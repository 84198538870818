.logo {
	display: flex;
	align-items: center;
	gap: 4px;
	position: relative;
}

.logo svg {
	width: 180px;
	height: 60px;
}

@media screen and (max-width: 931px) {
	.logo svg {
		width: 132px;
		height: 45px;
	}
}
