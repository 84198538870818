ul {
	list-style-type: none;
}
button {
	margin: 0;
	padding: 0;
	border: none;
	background: none;
	font: inherit;
	color: inherit;
	line-height: normal;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
}
