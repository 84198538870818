.directions-swiper .swiper-pagination {
    position: static;
    margin-top: 10px;
}

.directions-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 0;
}

.directions-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--primary-color);
}