.stat-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	font-family: Roboto;
}

.stat-item h3 {
	font-size: 60px;
	font-weight: 500;
	line-height: 96px;
	color: var(--primary-color);
}

.stat-item p {
	font-size: 18px;
	font-weight: 500;
	line-height: 29px;
	color: var(--text-color);
}

.stat-item span {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: var(--text-secondary-color);
}

@media screen and (max-width: 690px) {
	.stat-item {
		gap: 5px;
	}
}
