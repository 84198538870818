.work-stages-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 22px;
    width: 100%;
}

.work-stages-gallery img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: var(--rounding);
    cursor: pointer;
}

.works-stages-gallery-mobile {
    display: none;
    width: 100%;
}

.works-stages-gallery-swiper .swiper-pagination {
    position: static;
    margin-top: 10px;
}

.works-stages-gallery-swiper .swiper-pagination-bullet {
    border-radius: 0;
    height: 10px;
    width: 10px;
}

.works-stages-gallery-swiper .swiper-pagination-bullet-active {
    background: var(--primary-color);
}

@media screen and (max-width: 1240px) {
    .work-stages-other {
        margin-top: 45px;
        gap: 35px;
    }

    .work-stages-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 690px) {
    .work-stages-gallery {
        display: none;
    }

    .works-stages-gallery-mobile {
        display: flex;
        margin-top: -35px;
    }
}