.news-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 75px;
	flex-direction: column;
	gap: 70px;
	color: rgba(38, 40, 41, 1);
}

.news-big-container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 2.75fr 1.25fr;
	grid-template-rows: repeat(2, 1fr);
	row-gap: 20px;
	column-gap: 40px;
}

.news-big-container div:first-child {
	grid-row: span 2;
}

.news-big-container .news-card-image {
	aspect-ratio: 16 / 9;
}

.news-big-container[data-amount="1"] div:first-child {
	grid-column: span 2;
}


.news-big-container[data-amount="2"] div:nth-child(2) {
	grid-row: span 2;
}

.news-list {
	width: 100%;
	margin-top: 90px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 40px;
	row-gap: 20px;
}

.news-error-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 80vh;
}

@media screen and (max-width: 1240px) {
	.news-content {
		gap: 60px;
		padding-bottom: 40px;
	}

	.news-big-container {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	.news-heading-section {
		margin-top: -25px;
	}

	.news-list {
		display: none;
		margin-top: -20px;
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 690px) {
	.news-error-wrapper {
		min-height: auto;
	}
}
