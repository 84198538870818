.work-stages {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 70px;
	width: 100%;
}

.work-stages-items {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	width: 100%;
	column-gap: 66px;
	row-gap: 66px;
}

@media screen and (max-width: 1240px) {
	.work-stages-items {
		grid-template-columns: repeat(2, 1fr);
	}

	.work-stages {
		gap: 35px;
	}
}

@media screen and (max-width: 690px) {
	.work-stages-items {
		grid-template-columns: 1fr;
	}
}
