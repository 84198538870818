:root {
	--default-gap: 20px;
	--mobile-gap: 10px;
}

.header {
	width: 100%;
	border-bottom: 1px solid #e8e8e8;
	/*box-shadow: 0 4px 4px 0 #e8e8e8;*/
}

.header-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 21px;
	padding-bottom: 9px;
}

.logo-container {
	position: relative;
}

.logo-container svg {
	width: 180px;
	height: 60px;
}

.logo-active {
	position: absolute;
	bottom: 20px;
	right: 0;
	left: 40px;
	width: 1px;
	height: 1px;
	border-radius: 9999px;
	z-index: -10;
	background-color: var(--primary-color);
}

.nav-links {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 65px;
}

.header-actions {
	display: flex;
	align-items: center;
	gap: 35px;
}

.nav-links a {
	color: var(--text-color);
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.nav-links a:hover,
.mobile-nav-links a:hover {
	color: var(--primary-color);
}

.nav-links a.active,
.mobile-nav-links a.active {
	color: var(--primary-color);
}

.nav-links-item {
	position: relative;
}

.nav-links-item-underline {
	position: absolute;
	bottom: -5px;
	left: 0;
	right: 0;
	width: 100%;
	height: 2px;
	border-radius: 8px;
	background-color: var(--primary-color);
}

.header-actions-contact {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 12px 14px !important;
}

.header-actions-contact-mobile {
	cursor: pointer;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
}

.mobile-menu-container {
	display: none;
}

.menu-icon {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.menu-icon svg {
	width: 30px;
	height: 30px;
}

.mobile-nav-links ul {
	list-style: none;
	margin-top: 50px;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 36px;
}

.mobile-nav-links a {
	color: var(--text-color);
	text-align: center;
	font-family: Roboto, sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration: none;
}

/* Media Queries */
@media screen and (max-width: 1240px) {
	.nav-links {
		gap: 50px;
	}
}

@media screen and (max-width: 931px) {
	.mobile-menu-container {
		display: flex;
	}

	.mobile-menu {
		position: fixed;
		top: 79px;
		left: 0;
		right: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		border-top: 1px solid #e8e8e8;
	}

	.logo-container svg {
		width: 132px;
		height: 45px;
	}

	.logo-active {
		bottom: 20px;
		left: 20px;
	}

	.mobile-nav-links {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 36px;
	}

	.header-navigation {
		display: none;
	}
}
