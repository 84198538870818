.job-details-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 75px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: var(--text-color);
}

.job-details-heading {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	max-width: 720px;
	width: 100%;
	padding-bottom: 17px;
	border-bottom: 1px solid var(--text-secondary-color);
}

.job-details-heading h1 {
	font-family: Roboto, sans-serif;
	font-size: 44px;
	font-weight: 900;
	line-height: 57px;
	letter-spacing: 0.01em;
	text-align: left;
	margin: 0;
}

.job-details-heading-info {
	display: flex;
	align-items: center;
	gap: 50px;
}
.job-details-heading-info p {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	text-align: left;
}

.job-details-heading-info span {
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
}

.job-details-salary {
	display: flex;
	align-items: center;
	gap: 25px;
	font-family: Roboto, sans-serif;
	font-size: 26px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.01em;
	text-align: left;
}

.job-details-salary-tag {
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	text-align: left;
}

.job-details {
	display: grid;
	width: 100%;
	align-items: flex-start;
	gap: 67px;
	grid-template-columns: auto 390px;
}

.job-details-container {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.job-details-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
}

.job-details-item h2 {
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 23px;
	margin: 0;
	color: var(--text-secondary-color);
}

.job-details-item p {
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 31px;
}

.job-details-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
	gap: 30px;
}

.job-details-actions-date {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	text-align: left;
	align-self: flex-end;
	color: var(--text-secondary-color);
}

.job-details-more {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 10px;
	width: 100%;
}

.job-details-more h3 {
	margin: 0;
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 500;
	line-height: 31px;
}

.job-details-more-list {
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 100%;
}

.job-details-more-list-mobile {
	display: none;
}

.job-details-error-wrapper {
	min-height: 80vh;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1240px) {
	.job-details-content {
		padding-bottom: 38px;
		gap: 40px;
	}

	.job-details {
		display: flex;
		gap: 40px;
		flex-direction: column;
	}

	.job-details-actions {
		align-items: flex-start;
	}

	.job-details-more-list-mobile {
		display: flex;
		width: 100%;
	}

	.job-details-more-list {
		display: none;
	}

	.job-details-heading h1 {
		font-size: 42px;
		line-height: 55px;
		letter-spacing: 0.01em;
	}
}

@media screen and (max-width: 690px) {
	.job-details-error-wrapper {
		min-height: auto;
	}

	.job-details-actions {
		flex-direction: column;
		align-items: flex-start;
		gap: 15px;
	}

	.job-details-actions-date {
		align-self: auto;
	}

	.job-details-heading-info {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		margin-top: 10px;
	}
}
