.news-card {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: var(--rounding);
	overflow: hidden;
}

.news-card-image {
	position: relative;
	width: 100%;
	height: 100%;
}

.news-card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.news-card-image .backdrop {
	content: "";
	position: absolute;
	width: 100%;
	height: 70%;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
}

.news-card-content {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 24px;
	display: flex;
	gap: 10px;
	color: #ffffff;
	width: 100%;
}

.news-card-date {
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	align-self: flex-end;
	white-space: nowrap;
	position: absolute;
	right: 0;
	bottom: 0;
	color: #ffffff;
	padding: 0 12px 12px 0;
}

.news-card-title {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 80%;
}

.news-card-title h1 {
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	margin: 0;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical
}

.news-card-title p {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

.news-card-info {
	width: 100%;
	display: flex;
	gap: 10px;
	align-items: flex-start;
	flex-direction: column;
}

@media screen and (max-width: 1240px) {
	.news-card-content {
		color: #000000;
		position: relative;
		padding: 0;
		margin-top: 5px;
	}

	.news-card-date {
		color: #000000;
		padding: 0 12px 0 0;
	}

	.news-card-image {
		height: auto;
	}

	.news-card-info {
		flex-direction: column-reverse;
		gap: 20px;
	}

	.news-card-title h1 {
		font-family: Roboto, sans-serif;
		font-size: 18px;
		font-weight: 600;
		line-height: 29px;
	}

	.news-card-title p {
		display: none;
	}
}
