.section-heading {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.section-heading h1 {
	margin: 0;
	font-family: Roboto;
	font-size: 44px;
	font-weight: 900;
	line-height: 57px;
	letter-spacing: 0.01em;
	text-align: left;
}

.section-heading p {
	font-family: Roboto;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
}

@media screen and (max-width: 690px) {
	.section-heading h1 {
		font-size: 42px;
		line-height: 55px;
	}

	.section-heading p {
		font-size: 16px;
		line-height: 26px;
	}
}
