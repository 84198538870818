.card {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 680px;
}

.card-content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 15px 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.card-header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.card-header h1 {
	font-family: Roboto, sans-serif;
	font-size: 22px;
	font-weight: 600;
	line-height: 35px;
	text-align: left;
	color: #ffffff;
}

.card-header p {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
	color: #ffffff;
}

.card-button {
	max-width: fit-content;
}

.card-button button {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px 14px;
	max-width: 250px;
}

.card-button svg {
	width: 24px;
	height: 24px;
}

.card-button svg path {
	stroke: rgb(255, 255, 255);
}

.card-image {
	height: 100%;
	width: 100%;
	max-height: 300px;
	object-fit: cover;
	position: relative;
	filter: brightness(0.8);
}

.card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: var(--rounding);
}

@media screen and (max-width: 690px) {
	.card-header h1 {
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
	}

	.card-header p {
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
	}

	.card-content {
		padding: 10px;
	}

	.card-button button {
		background-color: transparent;
		padding: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		text-decoration: underline;
	}

	.card-button svg {
		display: none;
	}
}

@media screen and (max-width: 1240px) {
	.card-image:before {
		height: 99%;
	}
}
