.work-stages-item {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
}

.work-stages-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.work-stages-header svg {
	width: 60px;
	height: 60px;
}

.work-stages-header span {
	font-family: Roboto;
	font-size: 60px;
	font-weight: 900;
	line-height: 60px;
	text-align: left;
	color: rgba(241, 241, 241, 1);
	user-select: none;
}

.work-stages-item p {
	font-family: Roboto;
	font-size: 17px;
	font-weight: 500;
	line-height: 26px;
	text-align: left;
	color: var(--text-color);
	max-width: 310px;
}
