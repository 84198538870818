.empty-state {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.empty-state h2 {
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-weight: 800;
    line-height: 42px;
}

.empty-state p {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
}