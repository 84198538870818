.arrow-heading {
	display: flex;
	flex-direction: column;
	gap: 20px;
	color: var(--primary-color);
}

.arrow-heading-main {
	display: flex;
	align-items: center;
	gap: 3px;
}

.arrow-heading h2 {
	font-family: Roboto;
	font-size: 34px;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: left;
	color: inherit;
	margin: 0;
}

.arrow-heading p {
	font-family: Roboto;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
	color: var(--text-color);
}

@media screen and (max-width: 690px) {
	.arrow-heading h2 {
		font-size: 28px;
		line-height: 28px;
	}

	.arrow-heading p {
		font-size: 16px;
		line-height: 26px;
	}
}
