.rent-item {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	border-radius: var(--rounding);
	border: 1px solid rgba(217, 217, 217, 1);
}

.rent-item-content {
	display: flex;
	gap: 64px;
	width: 100%;
}

.rent-item-image {
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 2px;
	max-width: 360px;
	min-height: 240px;
	aspect-ratio: 3 / 2;
}

.rent-item-heading {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 30px;
}

.rent-item-heading p {
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;

}

.rent-item-price {
	display: flex;
	align-items: center;
	gap: 5px;
	white-space: nowrap;
}

.rent-item-price p {
	font-family: Roboto, sans-serif;
	font-size: 25px;
	font-weight: 500;
	line-height: 30px;
}

.rent-item-price span {
	font-family: Roboto, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
}

.rent-item-settings {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, 1fr);
	column-gap: 15px;
	row-gap: 25px;
}

.rent-item-settings-item {
	display: flex;
	align-items: center;
	gap: 10px;
}

.rent-item-settings-item p {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
}

.rent-item-settings-item span {
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	text-align: left;
}

.rent-item-button {
	align-self: flex-end;
}

.rent-item-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 1350px) {
	.rent-item-settings {
		display: grid;
		grid-template-columns: 1fr;
		column-gap: 15px;
		row-gap: 15px;
	}
}

@media screen and (max-width: 1240px) {
	.rent-item-content {
		flex-direction: column;
		gap: 30px;
	}

	.rent-item-image {
		max-width: none;
		min-height: auto;
	}

	.rent-item-heading {
		gap: 20px;
	}

	.rent-item-settings {
		grid-template-columns: 1fr;
		column-gap: 70px;
		row-gap: 25px;
	}

	.rent-item-footer {
		align-items: flex-start;
		gap: 14px;
	}

	.rent-item-button {
		align-self: flex-start;
	}

	.rent-item-settings-item {
		justify-content: space-between;
	}

	.rent-item-price p {
		font-size: 24px;
		line-height: 24px;
	}

	.rent-item-price span {
		font-size: 16px;
		line-height: 16px;
	}
}
