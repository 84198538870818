.stats-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 70px;
    margin-top: 90px;
    width: 100%;
}

@media screen and (max-width: 1240px) {
    .stats-container {
        gap: 25px;
        margin-top: 0;
    }
}