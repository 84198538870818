@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");

:root {
	--primary-color: rgba(5, 145, 217, 1);
	--text-color: rgba(38, 40, 41, 1);
	--text-secondary-color: rgba(160, 161, 159, 1);
	--widget-color: rgba(223, 244, 255, 1);
	--error-color: rgba(191, 19, 8, 1);
	--rounding: 2px;
}

body {
	margin: 0;
}

* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

.container {
	max-width: 1432px;
	width: 100%;
	padding: 0 1rem;
	margin: 0 auto;
}

.breadcrumbs {
	position: inherit;
	display: inline-flex;
	align-items: center;
	gap: 10px;
}
.breadcrumbs svg {
	width: 20px;
	height: 20px;
	align-self: flex-start;
}
.breadcrumbs svg path {
	fill: var(--color-additional-text, #a0a19f);
}

.breadcrumbs-text {
	display: flex;
	align-items: flex-start;
	gap: 6px;
}

.breadcrumbs-text a,
.breadcrumbs-text {
	color: var(--color-additional-text, #a0a19f);
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration: none;
}

.breadcrumbs-text a:last-child {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.PhotoView-Slider__Counter {
	font-family: Roboto, sans-serif;
	user-select: none;
}
