.error-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
}

.error-title {
    font-family: Roboto, sans-serif;
    font-size: 42px;
    font-weight: 900;
    line-height: 55px;
    text-align: left;
}

.error-message {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    max-width: 720px;
}

@media screen and (max-width: 690px) {
    .error-title {
        font-size: 36px;
        line-height: 45px;
    }
}