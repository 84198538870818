.form-main-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 70px;
	padding: 45px 38px 64px 38px;
	border-radius: var(--rounding);
	background: #ffffff;
	width: 100%;
}

.extended-contact-form-container {
	padding: 110px 0;
}

.form-main-content-container {
	gap: 160px;
	display: inline-flex;
	right: 40px;
	min-height: 1080px;
	flex-direction: column;
}

.form-main-content .content {
	width: 100%;
	display: grid;
	grid-template-columns: 424px auto;
	align-items: flex-start;
	gap: 36px;
}

.form-main-content .content-description {
	color: var(--text-color);
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	width: 100%;
}

.extended-contact-form {
	display: flex;
	flex-direction: column;
	gap: 25px;
	width: 100%;
}

.extended-contact-form .first-line {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;
}


.submit-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	margin-top: 40px;
}

.submit-container {
	color: rgba(55, 57, 59, 1);
}

.submit-container p {
	font-family: Roboto, sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 130%;
}

.submit-container p a {
	text-decoration-line: underline;
}

@media screen and (max-width: 1240px) {
	.form-main-content {
		gap: 25px;
		padding: 17px;
	}

	.form-main-content .content {
		grid-template-columns: 1fr;
	}

	.extended-contact-form-container {
		padding: 40px 0;
	}
}

@media screen and (max-width: 800px) {
	.extended-contact-form {
		gap: 20px;
	}

	.extended-contact-form .first-line {
		grid-template-columns: 1fr;
		row-gap: 20px;
	}
}
