.services-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 75px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: var(--text-color);
}

.services-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 160px;
}

.specialization-section {
	margin-top: 90px;
}

.services-error-wrapper {
	min-height: 70vh;
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1240px) {
	.services-content {
		gap: 60px;
		padding-bottom: 42px;
	}

	.services-list {
		gap: 60px;
		margin-top: 25px;
	}

	.specialization-section {
		margin-top: 0;
	}

	.catalog-section {
		margin-top: -25px;
	}
}

@media screen and (max-width: 490px) {
	.services-error-wrapper {
		min-height: auto;
	}
}
