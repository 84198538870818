.directions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
}

.directions-links-block {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    gap: 20px 50px;
    flex-wrap: wrap;
}

.directions-links-block .link {
    border-radius: var(--rounding);
    border: 1px solid var(--primary-color);
    display: flex;
    padding: 10px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.directions-links-block .link p {
    color: var(--color-title, #262829);
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
}

.directions-links-block .link:hover,
.directions-links-block .link.active {
    background: var(--primary-color);
}

.directions-links-block .link:hover p,
.directions-links-block .link.active p {
    color: #ffffff;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.directions-links-select-mobile {
    display: none;
}

.directions-select-container {
    width: 100%;
    z-index: 2;
}

.directions-select__control {
    border: none !important;
    background-color: var(--primary-color) !important;
}

.directions-select__control-is-focused {
    border: none !important;
}

.directions-select__value-container {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 25px;
}

.directions-select__menu {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 20px;
}

.directions-select__single-value {
    color: #ffffff !important;
}

.directions-select__indicator-separator {
    display: none;
}

.directions-select__indicators {
    padding: 2px 8px;
    color: #ffffff !important;
}

.directions-select__option--is-selected {
    background-color: var(--primary-color) !important;
}

@media screen and (max-width: 1240px) {
    .directions-links-block {
        display: none;
    }

    .directions-links-select-mobile {
        display: flex;
        width: 100%;
    }
}