.rent-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 70px;
    margin-top: 90px;
    width: 100%;
}

.rent-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /*grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));*/
    gap: 13px;
}

.rent-list-mobile {
    display: none;
    width: 100%;
}

.rent-button {
    width: 100%;
    height: 100%;
    min-height: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.rent-button:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.rent-button svg {
    width: 30px;
    height: 30px;
}

.rent-button svg path {
    stroke: rgb(255, 255, 255)
}

@media screen and (max-width: 1240px) {
    .rent-container {
        gap: 25px;
        margin-top: 0;
    }

    .rent-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1040px) {
    .rent-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 690px) {
    .rent-list {
        display: none;
    }

    .rent-list-mobile {
        display: flex;
    }
}

@media screen and (max-width: 490px) {
    .rent-list-mobile .mobile-swiper .swiper-slide img {
        max-height: 220px;
    }
}