.services-item {
	display: flex;
	flex-direction: row;
	gap: 40px;

}

.services-item:nth-child(even) {
	flex-direction: row-reverse;
}

.services-item-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex: 1;
	gap: 20px;
}

.services-item-image {
	flex: 1;
	cursor: pointer;
	aspect-ratio: 5 / 3;
	max-height: 450px;
}

.services-item-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: var(--rounding);
}

.services-item-content-button button {
	padding: 16px 16px 16px 27px;
	display: flex;
	align-items: center;
	gap: 16px;
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.services-item-content-button svg {
	width: 30px;
	height: 30px;
}

.services-item-content-button svg path {
	stroke: rgb(255, 255, 255);
}

@media screen and (max-width: 1240px) {
	.services-item,
	.services-item:nth-child(even) {
		flex-direction: column-reverse;
		gap: 10px;
	}

	.services-item-content-button {
		width: 100%;
	}

	.services-item-content-button button {
		margin-top: 25px;
		width: 100%;
	}

	.services-item-image {
		max-height: none;
	}
}
