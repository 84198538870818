@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #e4e4e4; /* Light grey */
    border-top: 5px solid var(--primary-color); /* Black */
    border-radius: 9999px;
    animation: spinner 1s linear infinite;
}