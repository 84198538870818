.projects-details {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    padding-bottom: 50px;
}

.projects-details-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
    color: var(--text-color);
}

.work-stages-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 22px;
    width: 100%;
}

.work-stages-gallery img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: var(--rounding);
    cursor: pointer;
}

.works-stages-gallery-mobile {
    display: none;
    width: 100%;
}

.works-stages-gallery-swiper .swiper-pagination {
    position: static;
    margin-top: 10px;
}

.works-stages-gallery-swiper .swiper-pagination-bullet {
    border-radius: 0;
    height: 10px;
    width: 10px;
}

.works-stages-gallery-swiper .swiper-pagination-bullet-active {
    background: var(--primary-color);
}

.services-details-error-wrapper {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    min-height: 70vh;
}

@media screen and (max-width: 1240px) {
    .services-details {
        padding-bottom: 38px;
    }

    .services-details-content {
        padding-bottom: 60px;
        gap: 60px;
    }

    .projects-details-heading-section {
        margin-top: -25px;
        margin-bottom: -25px;
    }

    .work-stages-other {
        margin-top: 45px;
        gap: 35px;
    }

    .work-stages-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 690px) {
    .work-stages-gallery {
        display: none;
    }

    .works-stages-gallery-mobile {
        display: flex;
        margin-top: -35px;
    }
}

