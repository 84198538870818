.project-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 40px;
	row-gap: 22px;
	width: 100%;
}

@media screen and (max-width: 1240px) {
	.project-list {
		display: flex;
		flex-direction: column;
		gap: 22px;
	}

	.project-list .card {
		max-width: none;
	}

	.project-list .card-image {
		max-height: none;
	}

	.project-list .card-image img {
		max-height: 600px;
	}
}