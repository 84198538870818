.recycling-info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 25px;
    row-gap: 50px;
    width: 100%;
}

.recycling-info-mobile {
    display: none;
    width: 100%;
}

.recycling-info-block {
    width: 100%;
    border-left: 1px solid var(--primary-color);
    padding: 0 25px;
    color: #37393b;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}

.recycling-info-block:last-child {
    border-right: 1px solid var(--primary-color);
}

.recycling-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 70px;
    margin-top: 90px;
    width: 100%;
}

@media screen and (max-width: 1240px) {
    .recycling-info {
        grid-template-columns: repeat(2, 1fr);
    }

    .recycling-info-block:last-child {
        border-right: none;
    }
}

@media screen and (max-width: 800px) {
    .recycling-info {
        display: none;
    }

    .recycling-info-mobile {
        display: flex;
    }

    .recycling-container {
        gap: 25px;
        margin-top: 0;
    }

    .recycling-info-block {
        font-size: 16px;
        line-height: 29px;
    }
}