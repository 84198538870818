.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 90px;
}

.footer-content {
	border-top: 1px solid var(--text-secondary-color);
	display: grid;
	grid-template-columns: 1.5fr 1fr 1.5fr 1.5fr;
	column-gap: 130px;
	padding-top: 25px;
}

.footer-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
}

.footer-column h2 {
	color: var(--text-color);
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.footer-column-list {
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
}

.footer-column li {
	align-items: flex-start;
	white-space: nowrap;
}

.footer-column li a {
	color: var(--color-text, #37393b);
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration: none;
}

.footer-column a:hover {
	color: var(--primary-color);
}

.footer-company {
	height: 63px;
	background-color: #37393b;
	min-width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
}

.footer-company p {
	color: #fff;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.address-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
}

.address-info a {
	color: var(--text-color);
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
}

.address-info p {
	color: var(--text-color);
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;
}

@media screen and (max-width: 1240px) {
	.footer-content {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 40px;
	}

	.footer-column {
		gap: 15px;
	}

	.footer-column-list {
		flex-direction: row;
		gap: 20px;
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 690px) {
	.footer-content {
		grid-template-columns: 1fr;
		row-gap: 40px;
	}

	.footer {
		gap: 38px;
	}
}
