.about-company-accordion-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
}

.about-company-accordion-item {
	display: flex;
	flex-direction: column;
	width: 100%;
	font-family: Roboto, sans-serif;
	border-bottom: 1px solid var(--text-secondary-color);
}

.about-company-accordion-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	padding: 10px 13px;
	border-radius: var(--rounding);
	transition: all 0.2s ease;
}

.about-company-accordion-title {
	font-family: Roboto, sans-serif;
	font-size: 22px;
	font-weight: 500;
	line-height: 31px;
	text-align: left;
}

.about-company-accordion-icon {
	width: 38px !important;
	height: 38px !important;
}

.about-company-accordion-button[aria-expanded="true"] {
	background-color: var(--primary-color);
	color: #ffffff;
}

.about-company-accordion-button:hover {
	background-color: var(--primary-color);
	color: #ffffff;
}

.about-company-accordion-body {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 25px 10px !important;
}

.about-company-accordion-body p {
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
}

@media screen and (max-width: 890px) {
	.about-company-accordion-body p {
		font-size: 16px;
		line-height: 26px;
	}

	.about-company-accordion-title {
		font-size: 18px;
		line-height: 25px;
	}

	.about-company-accordion-body {
		padding: 25px 10px 5px 10px !important;
	}
}