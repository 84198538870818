.jobs-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 75px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: rgba(38, 40, 41, 1);
}

.jobs-heading {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.jobs-heading h1 {
	margin: 0;
	font-family: Roboto, sans-serif;
	font-size: 44px;
	font-weight: 900;
	line-height: 57px;
	letter-spacing: 0.01em;
	text-align: left;
}

.jobs-heading p {
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
}

.jobs {
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 25px;
}

.jobs-list {
	display: flex;
	flex-direction: column;
	gap: 45px;
	width: 100%;
	min-height: 60vh;
}

.jobs-error-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 50vh;
}

.jobs-empty-wrapper {
	padding-top: 4rem;
}

@media screen and (max-width: 1240px) {
	.jobs-content {
		gap: 40px;
		padding-bottom: 38px;
	}
}

@media screen and (max-width: 490px) {
	.jobs-error-wrapper {
		min-height: auto;
	}
}
