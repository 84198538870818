.rent-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 75px;
	justify-content: center;
	position: relative; /* Make sure the video is positioned relative to this container */
	flex-grow: 1;
	flex-direction: column;
	gap: 70px;
	color: rgba(38, 40, 41, 1);
}

.rent-catalog {
	display: flex;
	flex-direction: column;
	gap: 45px;
	width: 100%;
}

.rent-catalog-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
	min-height: 70vh;
}

.rent-error-wrapper {
	min-height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rent-empty-wrapper {
	padding-top: 4rem;
}

@media screen and (max-width: 1240px) {
	.rent-content {
		gap: 60px;
		padding-bottom: 42px;
	}

	.rent-heading-section {
		margin-top: -25px;
	}

	.rent-catalog {
		gap: 60px;
	}

	.rent-catalog-container {
		gap: 28px;
	}
}

@media screen and (max-width: 490px) {
	.rent-error-wrapper {
		min-height: auto;
	}
}
