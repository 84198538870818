.last-projects {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: repeat(3, 1fr);
	column-gap: 40px;
	row-gap: 30px;
	width: 100%;
}

.last-projects .swiper {
	grid-row: span 3;
}

.last-projects .swiper:first-child {
	aspect-ratio: 16 / 9;
}

.last-projects .swiper:nth-child(2) {
	/*aspect-ratio: ;*/
}

.last-projects div:nth-child(2) {
	grid-row: span 2;
}

.last-projects .swiper .card,
.last-projects .card {
	max-width: none;
}

.last-projects .swiper .card-image {
	max-height: none;
}

.last-projects .swiper {
	overflow: hidden;
}

.last-projects-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 32px;
	color: #ffffff;
	border-radius: var(--rounding);
	background-color: rgba(55, 57, 59, 1);
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.last-projects-button[data-length = "1"] {
	grid-row: span 3;
}

.last-projects-button:hover {
	background-color: rgba(55, 57, 59, 0.9);

}

.last-projects-button svg {
	width: 29px;
	height: 29px;
}

.last-projects-button svg path {
	stroke: rgb(255, 255, 255);
}

.last-projects-mobile {
	display: none;
	width: 100%;
}

.last-projects-mobile .mobile-swiper .swiper-slide .card {
	max-width: none;
}

.last-projects-mobile .mobile-swiper .swiper-slide .card-image {
	max-height: none;
}

.last-project-all-button {
	width: 100%;
}

.last-project-all-button button {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
}

@media screen and (max-width: 1240px) {
	.last-projects {
		display: none;
	}

	.last-projects-mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 25px;
	}
}
