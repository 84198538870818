.other-news-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
	margin-top: 90px;
	width: 100%;
}

.other-news-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 40px;
	width: 100%;
}

.other-news-list .news-card-image {
	aspect-ratio: 16 / 9;
}

@media screen and (max-width: 1240px) {
	.other-news-list {
		grid-template-columns: 1fr;
		row-gap: 40px;
	}

	.other-news-container {
		margin-top: 0;
	}
}
