.mobile-swiper {
	z-index: 0;
}

.mobile-swiper .swiper-pagination {
	position: static;
	margin-top: 10px;
}

.mobile-swiper .swiper-pagination-bullet {
	border-radius: 0;
	height: 10px;
	width: 10px;
}

.mobile-swiper .swiper-pagination-bullet-active {
	background: var(--primary-color);
}

.mobile-swiper .swiper-slide img {
	width: 100%;
	height: 100%;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}
