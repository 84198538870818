.jobs-item {
	padding: 30px 20px 30px 20px;
	border-radius: 2px;
	border: 1px solid rgba(217, 217, 217, 1);
	color: var(--text-color);
}

.jobs-item-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.jobs-item-header-name {
	font-family: Roboto, sans-serif;
	font-size: 22px;
	font-weight: 500;
	line-height: 35px;
	text-align: left;
}

.jobs-item-header-contacts {
	display: flex;
	align-items: center;
	gap: 60px;
}

.jobs-item-header-contacts span {
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
}

.jobs-item-info {
	margin-top: 17px;
	display: flex;
	align-items: center;
	gap: 50px;
}

.jobs-item-info p {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	text-align: left;
}

.jobs-item-info span {
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
}

.jobs-item-footer {
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.jobs-item-footer-button {
	cursor: pointer;
}

.jobs-item-footer span {
	align-self: flex-end;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
}

@media screen and (max-width: 690px) {
	.jobs-item {
		padding: 20px;
	}

	.jobs-item-header-contacts {
		display: none;
	}

	.jobs-item-info {
		flex-direction: column;
		align-items: flex-start;
		gap: 6px;
	}

	.jobs-item-footer {
		margin-top: 40px;
	}
}
