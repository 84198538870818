.swiper {
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

.main-swiper {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.main-swiper .swiper-slide {
	aspect-ratio: 16 / 7;
	cursor: pointer;
}

.main-swiper .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.main-swiper .swiper-pagination-bullet {
	transition: background-color 0.2s ease;
	border-radius: 0;
	width: 10px;
	height: 10px;
}

.main-swiper .swiper-pagination-bullet-active {
	background-color: var(--primary-color);
}

.thumbs-swiper {
	height: 144px;
}

.thumbs-swiper .swiper-slide {
	height: 144px;
	opacity: 0.4;
	cursor: pointer;
	transition: 0.2s opacity ease;
}

.thumbs-swiper .swiper-slide img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.thumbs-swiper .swiper-slide:hover {
	opacity: 1;
}

.thumbs-swiper .swiper-slide-thumb-active {
	opacity: 1;
}

/* @media only screen and (max-width: 1260px) {
	.main-swiper {
		height: 400px;
	}
}

@media only screen and (max-width: 640px) {
	.main-swiper {
		height: 240px;
	}
} */
