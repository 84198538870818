.react-pagination {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 15px;
    padding: 0;
    margin: 0;
}

.react-pagination-page {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

.react-pagination-page:hover {
    color: var(--primary-color);
}

.react-pagination-page.selected {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-color);
}

.react-pagination-next-button {
    margin-left: 45px;
    cursor: pointer;
}

.react-pagination-next-button.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.react-pagination-next-button.disabled path {
    fill: var(--text-secondary-color);
}

.react-pagination-prev-button {
    margin-right: 45px;
    cursor: pointer;
}

.react-pagination-prev-button.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.react-pagination-prev-button.disabled path {
    fill: var(--text-secondary-color);
}

@media screen and (max-width: 1240px) {
    .react-pagination-prev-button {
        margin-right: 15px;
    }

    .react-pagination-next-button {
        margin-left: 15px;
    }
}