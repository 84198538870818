.progress-project {
    position: relative;
    width: 100%;
    height: 100%;
}

.progress-project-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: #ffffff;
    text-align: center;
    width: 100%;
    padding: 0 25px;
}

.progress-project-image {
    position: relative;
    width: 100%;
    height: 100%;

}

.progress-project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--rounding);
}

@media screen and (max-width: 690px) {
    .progress-project {
        font-size: 16px;
        line-height: 25px;
    }
}