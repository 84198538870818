.services-details {
	display: flex;
	flex-direction: column;
	padding-top: 25px;
	padding-bottom: 113px;
}

.services-details-content {
	display: flex;
	align-items: flex-start;
	padding-bottom: 123px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: var(--text-color);
}



.services-details-error-wrapper {
	display: flex;
	align-items: center;
	justify-self: flex-start;
	min-height: 70vh;
}

@media screen and (max-width: 1240px) {
	.services-details {
		padding-bottom: 38px;
	}

	.services-details-content {
		padding-bottom: 60px;
		gap: 60px;
	}

	.details-heading-section {
		margin-top: -25px;
		margin-bottom: -25px;
	}

}
