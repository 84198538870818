.stats {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 60px;
	row-gap: 30px;
	width: 100%;
}

@media screen and (max-width: 1240px) {
	.stats {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 690px) {
	.stats {
		grid-template-columns: repeat(1, 1fr);
	}
}
