.news-details-images {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.news-details-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 75px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: var(--text-color);
}

.news-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 40px;
	width: 100%;
}

.news-details-image {
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

.news-details-image img {
	object-fit: cover;
}

.news-details-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
}

.news-details-body h1 {
	font-family: Roboto, sans-serif;
	font-size: 24px;
	font-weight: 500;
	line-height: 38px;
}

.news-details-body p {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

.news-details-footer {
	display: flex;
	align-items: center;
	gap: 40px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.news-details-footer p {
	font-weight: 500;
}

.news-details-footer p span {
	font-weight: 400;
}

.news-details-footer .share-button {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 5px;
	border-bottom: 1px solid var(--text-color);
}

.news-details-footer .share-button svg {
	height: 24px;
	width: 24px;
}

.news-details-footer .share-button.copied {
	color: #388e3c;
	border-bottom-color: #388e3c;
}

.news-details-footer .share-button:hover {
	background-color: transparent;
}

.news-details-swiper-wrapper {
	width: 100%;
}

.news-details-swiper-wrapper-mobile {
	width: 100%;
	display: none;
}

@media screen and (max-width: 690px) {
	.news-details-swiper-wrapper {
		display: none;
	}

	.news-details-swiper-wrapper-mobile {
		display: flex;
	}

	.news-details-footer {
		flex-direction: column;
		gap: 15px;
		align-items: flex-start;
	}
}
