.form-background-container {
	background-image: url("../../images/contact-form-background-image.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	backdrop-filter: blur(8px);
	position: relative;
	width: 100%;
}

.form-background-container::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	backdrop-filter: blur(8px);
	z-index: -1;
}

.form-wrapper {
	display: flex;
	width: 100%;
	padding: 113px 0;
}

.form-content-container {
	display: flex;
	gap: 120px;
	width: 100%;
}

.form-content {
	display: flex;
	flex-direction: column;
	gap: 30px;
	background-color: #ffffff;
	border-radius: var(--rounding);
	padding: 30px 40px;
}

.form-content h1 {
	font-family: Roboto, sans-serif;
	font-size: 26px;
	font-weight: 500;
	line-height: 42px;
}

.contact-us-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 35px;
	width: 100%;
}

.contact-us-submit-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-width: 360px;
}

.contact-us-submit-container button {
	max-width: 140px;
}

.form-contacts-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 40px;
	color: #ffffff;
	padding-top: 30px;
}

.form-contacts-content {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	gap: 20px;
}

.form-contacts-container h1 {
	font-family: Roboto, sans-serif;
	font-size: 26px;
	font-weight: 500;
	line-height: 42px;
}

.form-contacts-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
}

.form-contacts-item span {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	color: var(--text-secondary-color);
}

.form-contacts-item p {
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
}

.captcha-error {
	font-size: 12px;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	color: var(--error-color);
	align-self: flex-start;
	margin-top: -10px;
}

@media screen and (max-width: 900px) {
	.form-wrapper {
		padding: 40px 0;
	}

	.form-content-container {
		flex-direction: column;
		max-width: none;
		gap: 40px;
	}

	.form-contacts-container {
		padding: 0;
	}

	.form-content {
		padding: 16px 17px;
	}

	.contact-us-form {
		align-items: flex-start;
	}
}