.job-mini-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 15px;
	background-color: var(--widget-color);
}

.job-mini-item-name {
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 29px;
}

.job-mini-item-info {
	display: flex;
	align-items: center;
	gap: 20px;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.job-mini-item-info p {
	font-weight: 500;
}

.job-mini-item-info span {
	font-weight: 400;
}

.job-mini-item-salary {
	display: flex;
	align-items: center;
	gap: 8px;
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 20px;
	margin-top: 30px;
}

.job-mini-item-salary-tag {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
}

@media screen and (max-width: 1240px) {
	.job-mini-item {
		padding: 15px;
	}
}

@media screen and (max-width: 400px) {
	.job-mini-item-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0;
		margin-top: 10px;
	}

	.job-mini-item-salary {
		margin-top: 15px;
	}
}