.custom-button {
	padding: 12px 23px 12px 23px;
	border-radius: 2px;
	background-color: var(--primary-color);
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	color: #ffffff;
	cursor: pointer;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.2s ease-in-out;
}

.custom-button:hover {
	background-color: rgba(1, 104, 157, 1);
}
