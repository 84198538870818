:root {
	--color-bg: #ffffff;
	--color-focus: #068bcc;
	--color-text: #37393b;
	--color-additional-text: #a0a19f;
	--margin-title: 70px;
	--color-title: #262829;
}
p {
	margin: 0;
}

.projects-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 50px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: rgba(38, 40, 41, 1);
}

.projects {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 70px;
	min-height: 85vh;
}

.projects-title {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
}

.projects-title .title {
	margin: 0;
	color: var(--color-title, #262829);
	font-family: Roboto, sans-serif;
	font-size: 44px;
	font-style: normal;
	font-weight: 900;
	line-height: 130%; /* 57.2px */
	letter-spacing: 0.44px;
}

.projects-title .sub-title {
	color: var(--color-text, #37393b);
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 27.2px */
}


.projects-list-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 60px;
	width: 100%;
	min-height: 35vh;
}

.projects-error {
	text-align: left;
}

.projects-error h1 {
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	color: var(--text-color)
}

.projects-list-container-mobile {
	display: none;
	width: 100%;
	margin-top: -25px;
}

.projects-error-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 35vh;
}

@media screen and (max-width: 1240px) {
	.projects-content {
		gap: 35px;
		padding-bottom: 38px;
	}

	.projects {
		gap: 60px;
	}
}

@media screen and (max-width: 690px) {
	.projects {
		min-height: 40vh;
	}

	.projects-list-container {
		display: none;
	}

	.projects-list-container-mobile {
		display: flex;
	}

	.projects-list-container-mobile .mobile-swiper .swiper-slide img {
		min-height: 200px;
	}

	.projects-error-wrapper {
		min-height: auto;
	}
}
