:root {
	--color-bg: #ffffff;
	--color-focus: #068bcc;
	--color-text: #37393b;
	--color-additional-text: #a0a19f;
	--margin-title: 70px;
	--color-title: #262829;
}

.company-content {
	display: flex;
	align-items: flex-start;
	padding-top: 25px;
	padding-bottom: 83px;
	justify-content: center;
	flex-direction: column;
	gap: 70px;
	color: var(--text-color);
}

.company {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 70px;
}

.company-title {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
}

.company-title p {
	margin: 0;
	color: var(--color-title, #262829);
	font-family: Roboto, sans-serif;
	font-size: 44px;
	font-style: normal;
	font-weight: 900;
	line-height: 130%;
	letter-spacing: 0.44px;
}

.company-info {
	display: flex;
	justify-content: space-between;
	gap: 80px;
	width: 100%;
}

.company-info-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
	flex: 1;
}
.company-info-text p {
	color: var(--text-color);
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.company-info-image {
	flex: 1;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.company-info-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.company-card {
	width: 100%;
	height: 100%;
	border-radius: var(--rounding, 2px);
	position: relative;
	display: flex;
}

.company-card-content {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 15px 20px;
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
	justify-content: space-between;
	z-index: 1;
}

.company-card-content a {
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	text-decoration: underline;
	color: #ffffff;
}

.company-card-content p {
	font-family: Roboto, sans-serif;
	font-size: 23px;
	font-weight: 700;
	line-height: 35px;
	text-align: left;
	color: #ffffff;
	position: relative;
	z-index: 2;
}

.company-card:hover .company-card-backdrop {
	background-color: rgba(0, 0, 0, 0.1);
}

.company-card-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	transition: background-color 0.2s ease-in-out;
	z-index: 1;
}

.company-card-last {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: #ffffff;
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 32px;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.company-card img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	aspect-ratio: 46 / 33;
}


@media screen and (max-width: 1240px) {
	.company-info-text {
		margin-top: -59px;
	}

	.company-info-image {
		display: none
	}

	.company {
		margin-top: -27px;
		gap: 60px;
	}

	.company-content {
		gap: 60px
	}

	.company-content {
		padding-bottom: 38px;
	}
}


@media screen and (max-width: 690px) {
	.company-card-content {
		flex-direction: column;
	}
}
