.section-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 70px;
}

.main-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-bottom: 110px;
}

.background-container {
	position: relative;
	width: 100%;
	overflow: hidden;
}

#background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	filter: brightness(0.5);
}

.hero {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 100px;
	position: relative;
	z-index: 1;
	color: #000;
	padding: 2rem 0;
}

.hero-title {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
}

.hero-title-text {
	color: #ffffff;
	font-family: Roboto, sans-serif;
	font-size: 68px;
	font-style: normal;
	font-weight: 900;
	line-height: 130%;
	letter-spacing: 0.01em;
}

.hero-title-text-sub {
	color: #ffffff;
	font-family: Roboto, sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 150%;
}

.hero-features {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 140px;
	row-gap: 35px;
}

.feature-item {
	display: flex;
	align-items: center;
	gap: 25px;
}

.feature-item-circle {
	width: 22px;
	height: 22px;
	border-radius: 9999px;
	background-color: var(--primary-color);
	box-shadow: 0 0 4px 5px rgba(5, 145, 217, 0.05);
}

.feature-item-text {
	font-family: Roboto, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;
	color: #ffffff;
}

.main-info-container {
	margin-top: 160px;
	margin-bottom: 110px;
	display: flex;
	flex-direction: column;
	gap: 160px;
}

.main-about-company-content {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 80px;
}

.main-about-company-images {
	display: grid;
	grid-template-columns: 0.8fr 1fr;
	grid-template-rows: repeat(2, 1fr);
	column-gap: 20px;
	row-gap: 10px;
	flex: 1;
	width: 100%;
}

.main-about-company-images img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: var(--rounding);
}

.main-about-company-images img:first-child {
	object-position: -90px;
	grid-row: span 2;
}

.main-about-company-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 25px;
	color: var(--text-color);
	flex: 1;
}

.main-about-company-info h2 {
	font-family: Roboto, sans-serif;
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
	text-align: left;
}

.main-about-company-text {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.main-about-company-text p {
	font-family: Roboto, sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
}

@media screen and (max-width: 1240px) {
	.main-content {
		padding-bottom: 38px;
	}

	.hero-features {
		column-gap: 80px;
		row-gap: 20px;
	}

	.hero {
		min-height: 80vh;
	}

	.section-container {
		gap: 25px;
	}

	.main-info-container {
		margin-top: 60px;
		margin-bottom: 48px;
		gap: 50px;
	}

	.main-about-company-content {
		flex-direction: column;
		gap: 20px;
	}
}

@media screen and (min-width: 1400px) {
	.hero {
		gap: 140px
	}
}

@media screen and (max-width: 1030px) {
	.hero {
		gap: 144px;
		min-height: 70vh;
	}

	.hero-features {
		grid-template-columns: 1fr;
	}

	.hero-title-text {
		font-size: 42px;
		line-height: 55px;
		letter-spacing: 0.01em;
	}

	.feature-item-text {
		font-size: 17px;
		line-height: 20px;
	}
}

@media screen and (max-width: 690px) {
	.main-about-company-images {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.main-about-company-images img {
		aspect-ratio: 16/9;
	}

	.main-about-company-images img:first-child {
		object-position: center;
	}
}